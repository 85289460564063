<template>
  <div class="pre_content">
    <div class="pre_battle">
      <div class="who_battle">
        <div class="inviter">
          <div class="head">
            <img
              v-show="battleUserInfo.avatar"
              :src="
                battleUserInfo.avatar
                  ? battleUserInfo.avatar
                  : './images/inviter.png'
              "
              alt=""
            />
          </div>
          <div>
            <p>
              {{ battleUserInfo.nickName ? battleUserInfo.nickName : "" }}
            </p>
          </div>
        </div>
        <div class="vs"><i>vs</i></div>
        <div class="invitee">
          <div class="head">
            <img
              v-show="myUserInfo.avatar"
              :src="
                myUserInfo.avatar ? myUserInfo.avatar : './images/invitee.png'
              "
              alt=""
            />
          </div>
          <div>
            <p>
              {{ myUserInfo.nickName ? myUserInfo.nickName : "" }}
            </p>
          </div>
        </div>
      </div>
      <div class="button_yello battle_tishi">邀请成功</div>
    </div>
    <div v-show="isready" class="battle_operate">准备成功</div>
    <el-button
      v-show="!isready"
      type="primary"
      :loading="loading"
      round
      @click="Preparation"
      >{{ buttonText }}</el-button
    >
  </div>
</template>

<script>
import {
  matchInvite, ready, waitStartBattle, wxLogin, getUserInfo
} from "@/api"
import { Notify } from 'vant'
export default {
  name: "Preparation",
  components: {
    "van-notify": Notify,

  },
  data () {
    return {
      baseUrl: window.__baseUrl__,
      inviteId: '',
      battleUserInfo: {},//对战人用户信息
      myUserInfo: {
        nickName: "",
        avatar: ""
      },
      buttonText: '加载中',
      loading: false,
      battleId: '344',//对战id
      code: '',//微信授权登录code
      isready: false,
      pretimeout: null
    }
  },
  mounted () {

    const { query } = this.$route
    this.inviteId = query.inviteId ? query.inviteId : ''
    this.code = this.getUrlCode()

    //接受邀请
    this.CodeBtn()

  },
  methods: {
    getUrlCode () { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.slice(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      console.log(theRequest, 'theRequest')
      return (theRequest && theRequest.code) ? theRequest.code : ''
    },


    //授权
    CodeBtn () {
      // console.log(this.code, 'this.code')

      let appid = process.env.VUE_APP_WX_APPID_H5
      let local = encodeURIComponent(window.location.href)//获取当前url
      // let local = encodeURIComponent('https://testweb.unodostech.com/#/preparation')
      if (!this.code || this.code == "") {
        //不存在就打开上面的地址进行授权
        window.location.href =
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect#wechat_redireact`
      } else {
        //微信登录
        wxLogin({ code: this.code, wxFrom: 'h5' }).then((d) => {
          console.log(this.code, d, 'this.code')
          if (d.code == 200) {
            // this.set('token', "Bearer " + d.token)
            localStorage.setItem("token", "Bearer " + d.token)
            // 测试
            // this.set('token', "Bearer " + "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjgxNDMxNDQ2LWNhMDYtNDgyNS04NDQyLWNjYWFhYWY5MmRhNyJ9.1cl1Ww7GZM61HKiLU4Qu8-hY6GL6V1AVoDg_QA8zNm7FpTP63TN34X-0myvFu753Ymiq-VJx94CeEh3O8g3X4Q")
            //获取用户信息
            getUserInfo().then((d) => {
              this.myUserInfo.nickName = d.user.nickName
              this.myUserInfo.avatar = (d.user.avatar && d.user.avatar.indexOf("http") == -1) ? this.baseUrl + d.user.avatar : d.user.avatar
            })

            //2接受邀请
            matchInvite({ inviteId: this.inviteId }).then((d) => {

              if (d.code == 200 && d.data.isSuccess) {
                this.battleUserInfo = d.data.battleUserInfo
                this.battleUserInfo.avatar = (this.battleUserInfo.avatar && this.battleUserInfo.avatar.indexOf("http") == -1) ? this.baseUrl + this.battleUserInfo.avatar : this.battleUserInfo.avatar
                this.buttonText = '准备'
                this.loading = false

              } else {
                this.$router.push('/wrong')

              }
            })
          } else {
            this.$notify(d.msg)
          }
        })
      }
    },

    set (key, value) {
      // var curtime = new Date().getTime()//获取当前时间
      localStorage.setItem(key, value)
      // localStorage.setItem(key, { val: JSON.stringify(value), time: curtime })//转换成json字符串序列
    },
    Preparation () {
      this.loading = true
      //准备 等待开始对战
      if (this.pretimeout !== null) clearTimeout(this.pretimeout)
      this.pretimeout = setTimeout(() => {
        ready({
          inviteId: this.inviteId
        }).then((d) => {
          if (d.code == 200) {
            this.isready = true
            this.buttonText = '准备成功'
            this.loading = false
            this.waitStart()

          } else {
            this.$notify(d.msg)
          }

        })
      }, 300)



    },

    waitStart () {
      waitStartBattle({ inviteId: this.inviteId }).then((d) => {
        // console.log(d, 'ddd')
        if (d.code == 200) {
          // this.loading = false
          this.battleId = d.battleld

          let urldomain = window.location.href
          console.log(urldomain)
          let urlarr = urldomain.split("?")
          window.location.href = `${urlarr[0]}#/subject?battleId=${d.battleId}&battleUserInfo=${JSON.stringify(this.battleUserInfo)}&myUserInfo=${JSON.stringify(this.myUserInfo)}`
          // window.location.href = `${window.location.host}/#/?battleId=${d.battleId}&battleUserInfo=${JSON.stringify(this.battleUserInfo)}&myUserInfo=${JSON.stringify(this.myUserInfo)}`
          // this.$router.push({ name: 'Subject', params: { battleId: d.battleId, battleUserInfo: JSON.stringify(this.battleUserInfo), myUserInfo: JSON.stringify(this.myUserInfo) } })//没跑通

        } else {
          this.$notify(d.msg)
        }
      })
    }



  }
}

</script>

<style lang="less" scoped>
/deep/.el-button {
  width: 57.7vw;
  // height: 5.7vh;
  height: 48px;
  opacity: 1;
  border: none;
  // border-radius: 224px;
  background: rgba(87, 212, 137, 1);

  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}
.pre_content {
  width: 100%;
  height: 100vh;
  transition: all 0.6s ease;
  background: linear-gradient(
    180deg,
    rgba(229, 251, 243, 1) 0%,
    rgba(241, 255, 239, 1) 60.72%,
    rgba(247, 247, 247, 1) 100%
  );
  padding: 22.7vh 7vw 0;
  text-align: center;
  .pre_battle {
    width: 100%;
    height: 27.3vh;
    margin-bottom: 80px;
    opacity: 1;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(40px);
    position: relative;
    .who_battle {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      .inviter {
        width: 45%;
      }
      .vs {
        width: 10%;
      }
      .invitee {
        width: 45%;
      }
      .head {
        width: 80px;
        height: 80px;
        opacity: 1;
        border-radius: 50%;
        background-color: #fff;
        margin: 0 auto;
        img {
          border-radius: 50%;
        }
      }
      .vs {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 29.02px;
        color: rgba(29, 29, 31, 1);
        margin-top: -40px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30px;
        color: rgba(29, 29, 31, 1);
        text-align: center;
      }
    }
    .battle_tishi {
      width: 26vw;
      // padding: 0 5.9vw;
      height: 26px;
      // width: 102px;
      // height: 26px;
      line-height: 26px;
      // line-height: 26px;
      z-index: 999;
      position: absolute;
      top: -1.6vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .battle_operate {
    margin: 0 auto;
    color: #57d489;
    font-size: 20px;
  }
}
</style>